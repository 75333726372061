import { default as billing5QJriXNBGkMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companykaJphiyvK4Meta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as planSspGrodEWHMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as index2JabHB2LU9Meta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as _91token_93YPwIVKPW5eMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexiOKCoUQt1pMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexoyalvzkyGOMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45roleR5qklS8mDiMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexYE0fW1986GMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduledHQqAh3k9MbMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as index4DIkwOpL5ZMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/index.vue?macro=true";
import { default as loginGZWUFrxxCUMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/login.vue?macro=true";
import { default as logoutHQQOtfxEvlMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/logout.vue?macro=true";
import { default as indexAudJ5dibJPMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as createvRIDEGgypVMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as importyELTyOq3QAMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsLGUjcEDnwIMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as indexOLgxwo4GKkMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirementsLgLgfBgQ57Meta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editFX9tltgtTgMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as detailsFBwVrTmVILMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as discardedIQZMuyvUbgMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue?macro=true";
import { default as index0sBqKzuAGgMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelineekbCVJpq3oMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchJa0cACNnEgMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indextl2pTuFsTGMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45resetmx2IcHMsBeMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as indexwU7bivKQTNMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/register/index.vue?macro=true";
import { default as password_45setupqqaNlqZrqWMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setupTVm7wQ2iBxMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buyGsty6waZJXMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodGnBQEr1ZLqMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successfulCfO2g7k2ClMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updateYikgZFA0JMMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as indexHegWOCkqAqMeta } from "/codebuild/output/src1253131903/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta } from "/codebuild/output/src1253131903/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.20_encoding@0.1.13_eslint@8.57.0_meow@12._yas64arccvxgv55up2qcrpbnyy/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: index2JabHB2LU9Meta?.name ?? "account___es",
    path: index2JabHB2LU9Meta?.path ?? "/account",
    meta: index2JabHB2LU9Meta || {},
    alias: index2JabHB2LU9Meta?.alias || [],
    redirect: index2JabHB2LU9Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billing5QJriXNBGkMeta?.name ?? "account-index-billing___es",
    path: billing5QJriXNBGkMeta?.path ?? "billing",
    meta: billing5QJriXNBGkMeta || {},
    alias: billing5QJriXNBGkMeta?.alias || [],
    redirect: billing5QJriXNBGkMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companykaJphiyvK4Meta?.name ?? "account-index-company___es",
    path: companykaJphiyvK4Meta?.path ?? "company",
    meta: companykaJphiyvK4Meta || {},
    alias: companykaJphiyvK4Meta?.alias || [],
    redirect: companykaJphiyvK4Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: planSspGrodEWHMeta?.name ?? "account-index-plan___es",
    path: planSspGrodEWHMeta?.path ?? "plan",
    meta: planSspGrodEWHMeta || {},
    alias: planSspGrodEWHMeta?.alias || [],
    redirect: planSspGrodEWHMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2JabHB2LU9Meta?.name ?? "account___en",
    path: index2JabHB2LU9Meta?.path ?? "/en/account",
    meta: index2JabHB2LU9Meta || {},
    alias: index2JabHB2LU9Meta?.alias || [],
    redirect: index2JabHB2LU9Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billing5QJriXNBGkMeta?.name ?? "account-index-billing___en",
    path: billing5QJriXNBGkMeta?.path ?? "billing",
    meta: billing5QJriXNBGkMeta || {},
    alias: billing5QJriXNBGkMeta?.alias || [],
    redirect: billing5QJriXNBGkMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companykaJphiyvK4Meta?.name ?? "account-index-company___en",
    path: companykaJphiyvK4Meta?.path ?? "company",
    meta: companykaJphiyvK4Meta || {},
    alias: companykaJphiyvK4Meta?.alias || [],
    redirect: companykaJphiyvK4Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: planSspGrodEWHMeta?.name ?? "account-index-plan___en",
    path: planSspGrodEWHMeta?.path ?? "plan",
    meta: planSspGrodEWHMeta || {},
    alias: planSspGrodEWHMeta?.alias || [],
    redirect: planSspGrodEWHMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91token_93YPwIVKPW5eMeta?.name ?? "auth-reset-password-token___es",
    path: _91token_93YPwIVKPW5eMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93YPwIVKPW5eMeta || {},
    alias: _91token_93YPwIVKPW5eMeta?.alias || [],
    redirect: _91token_93YPwIVKPW5eMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93YPwIVKPW5eMeta?.name ?? "auth-reset-password-token___en",
    path: _91token_93YPwIVKPW5eMeta?.path ?? "/en/auth/reset-password/:token()",
    meta: _91token_93YPwIVKPW5eMeta || {},
    alias: _91token_93YPwIVKPW5eMeta?.alias || [],
    redirect: _91token_93YPwIVKPW5eMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexiOKCoUQt1pMeta?.name ?? "auth-reset-password___es",
    path: indexiOKCoUQt1pMeta?.path ?? "/auth/reset-password",
    meta: indexiOKCoUQt1pMeta || {},
    alias: indexiOKCoUQt1pMeta?.alias || [],
    redirect: indexiOKCoUQt1pMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexiOKCoUQt1pMeta?.name ?? "auth-reset-password___en",
    path: indexiOKCoUQt1pMeta?.path ?? "/en/auth/reset-password",
    meta: indexiOKCoUQt1pMeta || {},
    alias: indexiOKCoUQt1pMeta?.alias || [],
    redirect: indexiOKCoUQt1pMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexoyalvzkyGOMeta?.name ?? "candidates-idcandidate___es",
    path: indexoyalvzkyGOMeta?.path ?? "/candidates/:idcandidate()",
    meta: indexoyalvzkyGOMeta || {},
    alias: indexoyalvzkyGOMeta?.alias || [],
    redirect: indexoyalvzkyGOMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoyalvzkyGOMeta?.name ?? "candidates-idcandidate___en",
    path: indexoyalvzkyGOMeta?.path ?? "/en/candidates/:idcandidate()",
    meta: indexoyalvzkyGOMeta || {},
    alias: indexoyalvzkyGOMeta?.alias || [],
    redirect: indexoyalvzkyGOMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleR5qklS8mDiMeta?.name ?? "candidates-best-by-role___es",
    path: best_45by_45roleR5qklS8mDiMeta?.path ?? "/candidates/best-by-role",
    meta: best_45by_45roleR5qklS8mDiMeta || {},
    alias: best_45by_45roleR5qklS8mDiMeta?.alias || [],
    redirect: best_45by_45roleR5qklS8mDiMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleR5qklS8mDiMeta?.name ?? "candidates-best-by-role___en",
    path: best_45by_45roleR5qklS8mDiMeta?.path ?? "/en/candidates/best-by-role",
    meta: best_45by_45roleR5qklS8mDiMeta || {},
    alias: best_45by_45roleR5qklS8mDiMeta?.alias || [],
    redirect: best_45by_45roleR5qklS8mDiMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: indexYE0fW1986GMeta?.name ?? "candidates___es",
    path: indexYE0fW1986GMeta?.path ?? "/candidates",
    meta: indexYE0fW1986GMeta || {},
    alias: indexYE0fW1986GMeta?.alias || [],
    redirect: indexYE0fW1986GMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: indexYE0fW1986GMeta?.name ?? "candidates___en",
    path: indexYE0fW1986GMeta?.path ?? "/en/candidates",
    meta: indexYE0fW1986GMeta || {},
    alias: indexYE0fW1986GMeta?.alias || [],
    redirect: indexYE0fW1986GMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: scheduledHQqAh3k9MbMeta?.name ?? "demo-scheduled___es",
    path: scheduledHQqAh3k9MbMeta?.path ?? "/demo/scheduled",
    meta: scheduledHQqAh3k9MbMeta || {},
    alias: scheduledHQqAh3k9MbMeta?.alias || [],
    redirect: scheduledHQqAh3k9MbMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: scheduledHQqAh3k9MbMeta?.name ?? "demo-scheduled___en",
    path: scheduledHQqAh3k9MbMeta?.path ?? "/en/demo/scheduled",
    meta: scheduledHQqAh3k9MbMeta || {},
    alias: scheduledHQqAh3k9MbMeta?.alias || [],
    redirect: scheduledHQqAh3k9MbMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: index4DIkwOpL5ZMeta?.name ?? "index___es",
    path: index4DIkwOpL5ZMeta?.path ?? "/",
    meta: index4DIkwOpL5ZMeta || {},
    alias: index4DIkwOpL5ZMeta?.alias || [],
    redirect: index4DIkwOpL5ZMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index4DIkwOpL5ZMeta?.name ?? "index___en",
    path: index4DIkwOpL5ZMeta?.path ?? "/en",
    meta: index4DIkwOpL5ZMeta || {},
    alias: index4DIkwOpL5ZMeta?.alias || [],
    redirect: index4DIkwOpL5ZMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginGZWUFrxxCUMeta?.name ?? "login___es",
    path: loginGZWUFrxxCUMeta?.path ?? "/login",
    meta: loginGZWUFrxxCUMeta || {},
    alias: loginGZWUFrxxCUMeta?.alias || [],
    redirect: loginGZWUFrxxCUMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginGZWUFrxxCUMeta?.name ?? "login___en",
    path: loginGZWUFrxxCUMeta?.path ?? "/en/login",
    meta: loginGZWUFrxxCUMeta || {},
    alias: loginGZWUFrxxCUMeta?.alias || [],
    redirect: loginGZWUFrxxCUMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHQQOtfxEvlMeta?.name ?? "logout___es",
    path: logoutHQQOtfxEvlMeta?.path ?? "/logout",
    meta: logoutHQQOtfxEvlMeta || {},
    alias: logoutHQQOtfxEvlMeta?.alias || [],
    redirect: logoutHQQOtfxEvlMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutHQQOtfxEvlMeta?.name ?? "logout___en",
    path: logoutHQQOtfxEvlMeta?.path ?? "/en/logout",
    meta: logoutHQQOtfxEvlMeta || {},
    alias: logoutHQQOtfxEvlMeta?.alias || [],
    redirect: logoutHQQOtfxEvlMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: createvRIDEGgypVMeta?.name ?? undefined,
    path: createvRIDEGgypVMeta?.path ?? "/offer/create",
    meta: createvRIDEGgypVMeta || {},
    alias: createvRIDEGgypVMeta?.alias || [],
    redirect: createvRIDEGgypVMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: indexAudJ5dibJPMeta?.name ?? "offer-create___es",
    path: indexAudJ5dibJPMeta?.path ?? "",
    meta: indexAudJ5dibJPMeta || {},
    alias: indexAudJ5dibJPMeta?.alias || [],
    redirect: indexAudJ5dibJPMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: createvRIDEGgypVMeta?.name ?? undefined,
    path: createvRIDEGgypVMeta?.path ?? "/en/offer/create",
    meta: createvRIDEGgypVMeta || {},
    alias: createvRIDEGgypVMeta?.alias || [],
    redirect: createvRIDEGgypVMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: indexAudJ5dibJPMeta?.name ?? "offer-create___en",
    path: indexAudJ5dibJPMeta?.path ?? "",
    meta: indexAudJ5dibJPMeta || {},
    alias: indexAudJ5dibJPMeta?.alias || [],
    redirect: indexAudJ5dibJPMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: importyELTyOq3QAMeta?.name ?? "offer-import___es",
    path: importyELTyOq3QAMeta?.path ?? "/offer/import",
    meta: importyELTyOq3QAMeta || {},
    alias: importyELTyOq3QAMeta?.alias || [],
    redirect: importyELTyOq3QAMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: importyELTyOq3QAMeta?.name ?? "offer-import___en",
    path: importyELTyOq3QAMeta?.path ?? "/en/offer/import",
    meta: importyELTyOq3QAMeta || {},
    alias: importyELTyOq3QAMeta?.alias || [],
    redirect: importyELTyOq3QAMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: editFX9tltgtTgMeta?.name ?? undefined,
    path: editFX9tltgtTgMeta?.path ?? "/offers/:idoffer()/edit",
    meta: editFX9tltgtTgMeta || {},
    alias: editFX9tltgtTgMeta?.alias || [],
    redirect: editFX9tltgtTgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsLGUjcEDnwIMeta?.name ?? "offers-idoffer-edit-details___es",
    path: detailsLGUjcEDnwIMeta?.path ?? "details",
    meta: detailsLGUjcEDnwIMeta || {},
    alias: detailsLGUjcEDnwIMeta?.alias || [],
    redirect: detailsLGUjcEDnwIMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: indexOLgxwo4GKkMeta?.name ?? "offers-idoffer-edit___es",
    path: indexOLgxwo4GKkMeta?.path ?? "",
    meta: indexOLgxwo4GKkMeta || {},
    alias: indexOLgxwo4GKkMeta?.alias || [],
    redirect: indexOLgxwo4GKkMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirementsLgLgfBgQ57Meta?.name ?? "offers-idoffer-edit-technical-requirements___es",
    path: technical_45requirementsLgLgfBgQ57Meta?.path ?? "technical-requirements",
    meta: technical_45requirementsLgLgfBgQ57Meta || {},
    alias: technical_45requirementsLgLgfBgQ57Meta?.alias || [],
    redirect: technical_45requirementsLgLgfBgQ57Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editFX9tltgtTgMeta?.name ?? undefined,
    path: editFX9tltgtTgMeta?.path ?? "/en/offers/:idoffer()/edit",
    meta: editFX9tltgtTgMeta || {},
    alias: editFX9tltgtTgMeta?.alias || [],
    redirect: editFX9tltgtTgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsLGUjcEDnwIMeta?.name ?? "offers-idoffer-edit-details___en",
    path: detailsLGUjcEDnwIMeta?.path ?? "details",
    meta: detailsLGUjcEDnwIMeta || {},
    alias: detailsLGUjcEDnwIMeta?.alias || [],
    redirect: detailsLGUjcEDnwIMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: indexOLgxwo4GKkMeta?.name ?? "offers-idoffer-edit___en",
    path: indexOLgxwo4GKkMeta?.path ?? "",
    meta: indexOLgxwo4GKkMeta || {},
    alias: indexOLgxwo4GKkMeta?.alias || [],
    redirect: indexOLgxwo4GKkMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirementsLgLgfBgQ57Meta?.name ?? "offers-idoffer-edit-technical-requirements___en",
    path: technical_45requirementsLgLgfBgQ57Meta?.path ?? "technical-requirements",
    meta: technical_45requirementsLgLgfBgQ57Meta || {},
    alias: technical_45requirementsLgLgfBgQ57Meta?.alias || [],
    redirect: technical_45requirementsLgLgfBgQ57Meta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextl2pTuFsTGMeta?.name ?? undefined,
    path: indextl2pTuFsTGMeta?.path ?? "/offers/:idoffer()",
    meta: indextl2pTuFsTGMeta || {},
    alias: indextl2pTuFsTGMeta?.alias || [],
    redirect: indextl2pTuFsTGMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsFBwVrTmVILMeta?.name ?? "offers-idoffer-index-details___es",
    path: detailsFBwVrTmVILMeta?.path ?? "details",
    meta: detailsFBwVrTmVILMeta || {},
    alias: detailsFBwVrTmVILMeta?.alias || [],
    redirect: detailsFBwVrTmVILMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: discardedIQZMuyvUbgMeta?.name ?? "offers-idoffer-index-discarded___es",
    path: discardedIQZMuyvUbgMeta?.path ?? "discarded",
    meta: discardedIQZMuyvUbgMeta || {},
    alias: discardedIQZMuyvUbgMeta?.alias || [],
    redirect: discardedIQZMuyvUbgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue").then(m => m.default || m)
  },
  {
    name: index0sBqKzuAGgMeta?.name ?? "offers-idoffer-index___es",
    path: index0sBqKzuAGgMeta?.path ?? "",
    meta: index0sBqKzuAGgMeta || {},
    alias: index0sBqKzuAGgMeta?.alias || [],
    redirect: index0sBqKzuAGgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelineekbCVJpq3oMeta?.name ?? "offers-idoffer-index-pipeline___es",
    path: pipelineekbCVJpq3oMeta?.path ?? "pipeline",
    meta: pipelineekbCVJpq3oMeta || {},
    alias: pipelineekbCVJpq3oMeta?.alias || [],
    redirect: pipelineekbCVJpq3oMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchJa0cACNnEgMeta?.name ?? "offers-idoffer-index-search___es",
    path: searchJa0cACNnEgMeta?.path ?? "search",
    meta: searchJa0cACNnEgMeta || {},
    alias: searchJa0cACNnEgMeta?.alias || [],
    redirect: searchJa0cACNnEgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextl2pTuFsTGMeta?.name ?? undefined,
    path: indextl2pTuFsTGMeta?.path ?? "/en/offers/:idoffer()",
    meta: indextl2pTuFsTGMeta || {},
    alias: indextl2pTuFsTGMeta?.alias || [],
    redirect: indextl2pTuFsTGMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsFBwVrTmVILMeta?.name ?? "offers-idoffer-index-details___en",
    path: detailsFBwVrTmVILMeta?.path ?? "details",
    meta: detailsFBwVrTmVILMeta || {},
    alias: detailsFBwVrTmVILMeta?.alias || [],
    redirect: detailsFBwVrTmVILMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: discardedIQZMuyvUbgMeta?.name ?? "offers-idoffer-index-discarded___en",
    path: discardedIQZMuyvUbgMeta?.path ?? "discarded",
    meta: discardedIQZMuyvUbgMeta || {},
    alias: discardedIQZMuyvUbgMeta?.alias || [],
    redirect: discardedIQZMuyvUbgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue").then(m => m.default || m)
  },
  {
    name: index0sBqKzuAGgMeta?.name ?? "offers-idoffer-index___en",
    path: index0sBqKzuAGgMeta?.path ?? "",
    meta: index0sBqKzuAGgMeta || {},
    alias: index0sBqKzuAGgMeta?.alias || [],
    redirect: index0sBqKzuAGgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelineekbCVJpq3oMeta?.name ?? "offers-idoffer-index-pipeline___en",
    path: pipelineekbCVJpq3oMeta?.path ?? "pipeline",
    meta: pipelineekbCVJpq3oMeta || {},
    alias: pipelineekbCVJpq3oMeta?.alias || [],
    redirect: pipelineekbCVJpq3oMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchJa0cACNnEgMeta?.name ?? "offers-idoffer-index-search___en",
    path: searchJa0cACNnEgMeta?.path ?? "search",
    meta: searchJa0cACNnEgMeta || {},
    alias: searchJa0cACNnEgMeta?.alias || [],
    redirect: searchJa0cACNnEgMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: password_45resetmx2IcHMsBeMeta?.name ?? "password-reset___es",
    path: password_45resetmx2IcHMsBeMeta?.path ?? "/password-reset",
    meta: password_45resetmx2IcHMsBeMeta || {},
    alias: password_45resetmx2IcHMsBeMeta?.alias || [],
    redirect: password_45resetmx2IcHMsBeMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetmx2IcHMsBeMeta?.name ?? "password-reset___en",
    path: password_45resetmx2IcHMsBeMeta?.path ?? "/en/password-reset",
    meta: password_45resetmx2IcHMsBeMeta || {},
    alias: password_45resetmx2IcHMsBeMeta?.alias || [],
    redirect: password_45resetmx2IcHMsBeMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: indexwU7bivKQTNMeta?.name ?? "register___es",
    path: indexwU7bivKQTNMeta?.path ?? "/register",
    meta: indexwU7bivKQTNMeta || {},
    alias: indexwU7bivKQTNMeta?.alias || [],
    redirect: indexwU7bivKQTNMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexwU7bivKQTNMeta?.name ?? "register___en",
    path: indexwU7bivKQTNMeta?.path ?? "/en/register",
    meta: indexwU7bivKQTNMeta || {},
    alias: indexwU7bivKQTNMeta?.alias || [],
    redirect: indexwU7bivKQTNMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: password_45setupqqaNlqZrqWMeta?.name ?? "register-password-setup___es",
    path: password_45setupqqaNlqZrqWMeta?.path ?? "/register/password-setup",
    meta: password_45setupqqaNlqZrqWMeta || {},
    alias: password_45setupqqaNlqZrqWMeta?.alias || [],
    redirect: password_45setupqqaNlqZrqWMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: password_45setupqqaNlqZrqWMeta?.name ?? "register-password-setup___en",
    path: password_45setupqqaNlqZrqWMeta?.path ?? "/en/register/password-setup",
    meta: password_45setupqqaNlqZrqWMeta || {},
    alias: password_45setupqqaNlqZrqWMeta?.alias || [],
    redirect: password_45setupqqaNlqZrqWMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setupTVm7wQ2iBxMeta?.name ?? "register-profile-setup___es",
    path: profile_45setupTVm7wQ2iBxMeta?.path ?? "/register/profile-setup",
    meta: profile_45setupTVm7wQ2iBxMeta || {},
    alias: profile_45setupTVm7wQ2iBxMeta?.alias || [],
    redirect: profile_45setupTVm7wQ2iBxMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setupTVm7wQ2iBxMeta?.name ?? "register-profile-setup___en",
    path: profile_45setupTVm7wQ2iBxMeta?.path ?? "/en/register/profile-setup",
    meta: profile_45setupTVm7wQ2iBxMeta || {},
    alias: profile_45setupTVm7wQ2iBxMeta?.alias || [],
    redirect: profile_45setupTVm7wQ2iBxMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: buyGsty6waZJXMeta?.name ?? "subscription-buy___es",
    path: buyGsty6waZJXMeta?.path ?? "/subscription/buy",
    meta: buyGsty6waZJXMeta || {},
    alias: buyGsty6waZJXMeta?.alias || [],
    redirect: buyGsty6waZJXMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: buyGsty6waZJXMeta?.name ?? "subscription-buy___en",
    path: buyGsty6waZJXMeta?.path ?? "/en/subscription/buy",
    meta: buyGsty6waZJXMeta || {},
    alias: buyGsty6waZJXMeta?.alias || [],
    redirect: buyGsty6waZJXMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodGnBQEr1ZLqMeta?.name ?? "subscription-payment-method___es",
    path: payment_45methodGnBQEr1ZLqMeta?.path ?? "/subscription/payment-method",
    meta: payment_45methodGnBQEr1ZLqMeta || {},
    alias: payment_45methodGnBQEr1ZLqMeta?.alias || [],
    redirect: payment_45methodGnBQEr1ZLqMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodGnBQEr1ZLqMeta?.name ?? "subscription-payment-method___en",
    path: payment_45methodGnBQEr1ZLqMeta?.path ?? "/en/subscription/payment-method",
    meta: payment_45methodGnBQEr1ZLqMeta || {},
    alias: payment_45methodGnBQEr1ZLqMeta?.alias || [],
    redirect: payment_45methodGnBQEr1ZLqMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: successfulCfO2g7k2ClMeta?.name ?? "subscription-successful___es",
    path: successfulCfO2g7k2ClMeta?.path ?? "/subscription/successful",
    meta: successfulCfO2g7k2ClMeta || {},
    alias: successfulCfO2g7k2ClMeta?.alias || [],
    redirect: successfulCfO2g7k2ClMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: successfulCfO2g7k2ClMeta?.name ?? "subscription-successful___en",
    path: successfulCfO2g7k2ClMeta?.path ?? "/en/subscription/successful",
    meta: successfulCfO2g7k2ClMeta || {},
    alias: successfulCfO2g7k2ClMeta?.alias || [],
    redirect: successfulCfO2g7k2ClMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: updateYikgZFA0JMMeta?.name ?? "subscription-update___es",
    path: updateYikgZFA0JMMeta?.path ?? "/subscription/update",
    meta: updateYikgZFA0JMMeta || {},
    alias: updateYikgZFA0JMMeta?.alias || [],
    redirect: updateYikgZFA0JMMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: updateYikgZFA0JMMeta?.name ?? "subscription-update___en",
    path: updateYikgZFA0JMMeta?.path ?? "/en/subscription/update",
    meta: updateYikgZFA0JMMeta || {},
    alias: updateYikgZFA0JMMeta?.alias || [],
    redirect: updateYikgZFA0JMMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: indexHegWOCkqAqMeta?.name ?? "users___es",
    path: indexHegWOCkqAqMeta?.path ?? "/users",
    meta: indexHegWOCkqAqMeta || {},
    alias: indexHegWOCkqAqMeta?.alias || [],
    redirect: indexHegWOCkqAqMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexHegWOCkqAqMeta?.name ?? "users___en",
    path: indexHegWOCkqAqMeta?.path ?? "/en/users",
    meta: indexHegWOCkqAqMeta || {},
    alias: indexHegWOCkqAqMeta?.alias || [],
    redirect: indexHegWOCkqAqMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.path ?? "/profile",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.20_encoding@0.1.13_eslint@8.57.0_meow@12._yas64arccvxgv55up2qcrpbnyy/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.path ?? "/comprar-suscripcion",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.20_encoding@0.1.13_eslint@8.57.0_meow@12._yas64arccvxgv55up2qcrpbnyy/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.path ?? "/offers",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4620_encoding_640_461_4613_eslint_648_4657_460_meow_6412iyAfuDP0ySMeta?.redirect,
    component: () => import("/codebuild/output/src1253131903/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.20_encoding@0.1.13_eslint@8.57.0_meow@12._yas64arccvxgv55up2qcrpbnyy/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]