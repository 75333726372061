/**

 FEATURE FLAGS
 Documentación:https://www.notion.so/talently/Feature-Flags-996e45712ee244c48b4e3dc4098deab0

 Example
 export const REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE =
 'REGULAR_SMART_SOURCING_HIRE_COMPANY_CANDIDATES_SEARCH_PAGE'

 **/

/* -------------------- REGULAR -------------------- */
export const FEAT_CHAT = 'feat_chat'
export const SPRINT_06_2024_TALENT_OPS_IMPROVE_OFFER_EXPERIENCE = 'sprint_06_2024_talent_ops_improve_offer_experience'
export const SQUAD_03_FREE_AIMED_SCHEDULING_CALLS = 'squad_03_20240724_free_aimed_scheduling_calls'
export const SQUAD_03_20240812_PROMOTIONAL_CODES = 'squad_03_20240812_promotional_codes_for_subscriptions'
export const SQUAD_01_20240903_MIGRATION_HIRE_REGISTER_CREATE_OFFER =
	'squad_01_20240903_migration_hire_register_create_offer'
export const SQUAD_01_20240924_HIRE_SEARCH_IMPROVES = 'squad_01_20240924_hire_search_improves'
export const SQUAD_02_NEW_FLOW_CANDIDATES = 'feature_flujo_de_candidatos'

/* -------------------- MINIS -------------------- */
export const MINI_RULES_FOR_REJECTED_OFFERS = 'mini_rules_for_rejected_offers'
export const MINI_CANDIDATE_MANAGEMENT_LIMIT = 'mini_candidate_management_limit'
export const MINI_ADJUST_PLAN_CANCELLATION_REASONS = 'mini_adjust_plan_cancellation_reasons'
export const MINI_FEATURES_FOR_MY_OFFERS = 'mini_features_for_my_offers'
export const MINI_SEARCH_CANDIDATE_UPDATE = 'mini_search_candidate_update'
export const SQ2_AI_TOP_RECOMMEND = 'sq2_ai_top_recommend'
export const SQ3_FREE_LIMITS = 'sq3_free_limits'
export const SQ2_AUTOMATION_15_PAUSE_5_DISABLE = 'sq2_automation_15_pause_5_disable'
export const SKUS_IMPLEMENTATION = 'squad_03_skus_implementation'

/* -------------------- BUGS -------------------- */

/* -------------------- TESTAB -------------------- */

/* -------------------- CONFIG -------------------- */
export const CONFIG_BANNER_HIRE = 'config_banner_hire'

/* ----------------- QUICK WINS ------------------ */
export const SQUAD_03_20241002_COMPANY_REGISTRATION_FIELD_ADJUSTMENTS =
	'squad_03_20241002_company_registration_field_adjustments'
